import React from 'react';
import Project from '../../api/project';
import { Link } from 'react-router-dom';
import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './responsive.css';

const ProjectSection = () => {
    const ClickHandler = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Define the category you want to filter by here (e.g., "IPDN")
    const selectedCategory = "KEMENHUB"; 

    // Filter projects based on the sekdin category
    const filteredProjects = Project.filter(project => project.sekdin === selectedCategory);

    return (
        <div className="portfolio_carousel">
            <Swiper
                modules={[Pagination, A11y]}
                slidesPerView={1}
                loop={true}
                spaceBetween={40}
                centeredSlides={true}
                pagination={{ clickable: true }}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                speed={600}
                breakpoints={{
                    1025: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    576: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                }}
            >
                {filteredProjects.slice(0, 5).map((project, prj) => (
                    <SwiperSlide key={prj}>
                        <div className="portfolio_block">
                            <div className="portfolio_image">
                                <Link onClick={ClickHandler} className="portfolio_image_wrap bg-light" to="/alumni">
                                    <img src={project.pImg} alt={project.nama} />
                                </Link>
                            </div>
                            <div className="portfolio_content">
                                <h3 className="portfolio_title">
                                    <Link onClick={ClickHandler} to="/alumni">
                                        {project.nama}
                                    </Link>
                                </h3>
                                <ul className="category_list unordered_list">
                                    <li><Link onClick={ClickHandler} to="/alumni">{project.sekolahKedinasan}</Link></li>
                                </ul>
                                <Link onClick={ClickHandler} className="btn btn-outline-light" to={`/portfolio_details/${project.sekdin}`} />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default ProjectSection;
