import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import aImg from '../../images/artikelsekdin/ssn1.png';
import aImg2 from '../../images/artikelsekdin/ssn2.jpeg';
import aImg3 from '../../images/artikelsekdin/ssn3.jpg';
import aImg4 from '../../images/artikelsekdin/ssn4.jpg';
import WhatsappButton from '../../components/WhatsappButton/whatsappbutton';

const SekdinPage = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Politeknik Siber dan Sandi Negara (Poltek SSN)'} />
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="Poltek SSN - About" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="Poltek SSN - About" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* New Section for Poltek SSN Information */}
                <section className="about-info-section bg-white py-5">
                    <div className="container">
                        <h1 className="section-title mb-4">Tentang Poltek SSN</h1>
                        <p style={{ textAlign: 'justify', marginBottom: '10px' }}>
                            Politeknik Siber dan Sandi Negara (Poltek SSN) adalah perguruan tinggi kedinasan di bawah Badan Siber dan Sandi Negara (BSSN) yang berfokus pada pendidikan vokasi di bidang keamanan siber dan kriptografi. Poltek SSN bertujuan menghasilkan tenaga profesional yang terampil dalam melakukan pengolahan dan analisis data statistik untuk mendukung kebijakan publik. Lulusan dari Poltek SSN siap bekerja di BSSN dan instansi pemerintah lainnya.
                        </p>
                        <p style={{ textAlign: 'justify', marginBottom: '40px' }}>
                            Poltek SSN menawarkan program studi yang meliputi D3 Keamanan Siber dan D4 Kriptografi dengan peminatan di berbagai bidang analisis data dan keamanan informasi. Selain itu, Poltek SSN juga memiliki jalur seleksi yang sangat kompetitif, memberikan kesempatan bagi siswa terbaik untuk menempuh pendidikan gratis dengan potensi menjadi PNS setelah lulus.
                        </p>
                        
                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg3} alt="Poltek SSN Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Kampus Poltek SSN - Menyiapkan Profesional di Bidang Keamanan Siber</p>
                        </div>

                        <h1 className="section-title mt-0">Persyaratan Pendaftaran Poltek SSN</h1>
                        <ol>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Umum</h5>
                                <li>Warga Negara Indonesia</li>
                                <li>Usia minimal 17 tahun dan maksimal 21 tahun pada 31 Desember tahun pendaftaran</li>
                                <li>Sehat jasmani dan rohani, serta bebas narkoba</li>
                                <li>Belum menikah dan bersedia tidak menikah selama mengikuti pendidikan di Poltek SSN hingga pengangkatan PNS</li>
                                <li>Tidak buta warna (baik total maupun parsial)</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Administrasi</h5>
                                <li>Pas foto terbaru</li>
                                <li>Kartu Identitas (KTP/KIA)</li>
                                <li>Kartu Keluarga</li>
                                <li>Akta Kelahiran</li>
                                <li>Ijazah dan Transkrip Nilai atau Rapor Kelas 12 Semester Ganjil</li>
                                <li>Surat Keterangan Orang Asli Papua (SKOAP) bagi pendaftar jalur afirmasi</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Khusus</h5>
                                <li>Tidak sedang menjalani atau terancam hukuman pidana karena melakukan kejahatan</li>
                                <li>Tidak bertindik atau bekas ditindik telinganya atau anggota badan lainnya bagi peserta pria, kecuali karena ketentuan agama/adat</li>
                                <li>Tidak bertato</li>
                                <li>Tidak menggunakan kacamata/lensa kontak</li>
                                <li>Belum pernah menikah/kawin, bagi pendaftar wanita belum pernah hamil/melahirkan</li>
                        </ol>

                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg4} alt="Poltek SSN Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Kampus Poltek SSN - Menyiapkan Profesional di Bidang Keamanan Siber</p>
                        </div>

                        <h1 className="marginBottom:'10px'">Fakultas dan Program Studi Poltek SSN</h1>
                        <div className="row">
                            {/* Fakultas Column */}
                            <div className="col-lg-6">
                                <h5>Fakultas</h5>
                                <ul>
                                    <li>Fakultas Keamanan Siber dan Kriptografi</li>
                                </ul>
                            </div>
                            {/* Program Studi Column */}
                            <div className="col-lg-6">
                                <h5>Program Studi</h5>
                                <ul>
                                    <li>D3 – Keamanan Siber</li>
                                    <li>D4 – Kriptografi</li>
                                    <li>D4 – Keamanan Jaringan</li>
                                    <li>D4 – Keamanan Perangkat Keras dan Lunak</li>
                                    <li>D4 – Penguji Penetrasi Web dan Jaringan</li>
                                </ul>
                            </div>
                            <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Sekolah Kedinasan Lain :</h3>
                        <div style={{ marginTop: '30px' }}><PartnerSection /></div>
                         <div className="text-center mt-5"> 
                             <Link
                                 to="/" 
                                 className="btn btn-primary"
                                 onClick={ClickHandler}
                                 style={{ display: 'inline-block' }} // Ensure the button is inline-block for centering
                             >
                                 <span className="btn_label" data-text="Kembali">Kembali</span>
                                 <span className="btn_icon">
                                     <i className="fa-solid fa-arrow-up-right"></i>
                                 </span>
                             </Link>  
                        </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <WhatsappButton />
        </Fragment>
    );
};

export default SekdinPage;
