import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import ProjectSekdinIPDN from '../../components/ProjectSection/ProjectSectionPOLTEKIM';
import aImg from '../../images/artikelsekdin/poltekim1.jpg';
import aImg2 from '../../images/artikelsekdin/poltekim2.jpg';
import aImg3 from '../../images/artikelsekdin/poltekim3.jpg';
import aImg4 from '../../images/artikelsekdin/poltekim4.jpg';
import WhatsappButton from '../../components/WhatsappButton/whatsappbutton';

const SekdinPage = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Politeknik Imigrasi (POLTEKIM)'} />
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="POLTEKIP & POLTEKIM - About" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="POLTEKIP & POLTEKIM - About" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* New Section for POLTEKIP & POLTEKIM Information */}
                <section className="about-info-section bg-white py-5">
                    <div className="container">
                        <h1 className="section-title mb-4">Tentang POLTEKIP & POLTEKIM</h1>
                        <p style={{ textAlign: 'justify', marginBottom: '10px' }}>
                            Politeknik Ilmu Pemasyarakatan (POLTEKIP) dan Politeknik Imigrasi (POLTEKIM) adalah perguruan tinggi kedinasan yang berada di bawah naungan Kementerian Hukum dan Hak Asasi Manusia Republik Indonesia. POLTEKIP berfokus pada pendidikan di bidang pemasyarakatan, sedangkan POLTEKIM berfokus pada pendidikan di bidang keimigrasian. Keduanya bertujuan untuk menghasilkan tenaga profesional yang siap bekerja di instansi pemerintah terkait.
                        </p>
                        <p style={{ textAlign: 'justify', marginBottom: '40px' }}>
                            POLTEKIP menawarkan program pendidikan di bidang manajemen dan teknik pemasyarakatan, sementara POLTEKIM menyediakan program studi dalam manajemen teknologi keimigrasian. Kedua perguruan tinggi ini memiliki seleksi yang ketat, dan lulusannya berkesempatan besar untuk menjadi PNS di kementerian terkait, dengan berbagai peluang karir di Indonesia.
                        </p>
                        
                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg3} alt="POLTEKIP & POLTEKIM Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Kampus POLTEKIP & POLTEKIM - Mencetak Profesional di Bidang Pemasyarakatan dan Keimigrasian</p>
                        </div>

                        <h1 className="section-title mt-0">Persyaratan Pendaftaran POLTEKIP & POLTEKIM</h1>
                        <ol>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Umum</h5>
                                <li>Lulusan SMA / MA / SMK</li>
                                <li>Kualifikasi Tinggi & Berat badan :Pria minimal 170 cm, Wanita 160 cm, Berat Badan menyesuaikan</li>
                                <li>Mata minus / plus / silinder tidak diperbolehkan</li>
                                <li>Buta warna parsial maupun total tidak diperbolehkan</li>
                                <li>Usia max. 23 tahun & min. 17 tahun per 1 April ditahun pendaftaran yang sama</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Akademik</h5>
                                <li>Tidak ada persyaratan akademik khusus.</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Berkas</h5>
                                <li>Surat lamaran bermaterai Rp. 10.000,- ditandatangani dengan tinta hitam (download di catar.kemenkumham.go.id)</li>
                                <li>KTP / Surat keterangan pengganti KTP dari dukcapil</li>
                                <li>Ijazah untuk lulusan tahun sebelum 2024 atau SKL Asli untuk ditahun pendaftaran yang sama</li>
                                <li>Akta Kelahiran</li>
                                <li>Surat keterangan belum pernah menikah ditandatangani lurah/kepala desa sesuai domisili</li>
                                <li>Surat pernyataan 6 poin bermaterai Rp. 10.000,- ditandatangani dengan tinta hitam (download di catar.kemenkumham.go.id) </li>
                                <li>Pas foto hadap depan background merah bagi pelamar POLTEKIP & Pas foto hadap depan</li>
                                <li>background biru bagi pelamar POLTEKIM</li>
                        </ol>

                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg4} alt="POLTEKIP & POLTEKIM Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Kampus POLTEKIP & POLTEKIM - Mencetak Profesional di Bidang Pemasyarakatan dan Keimigrasian</p>
                        </div>

                        <h1 className="marginBottom:'10px'">Fakultas dan Program Studi POLTEKIP & POLTEKIM</h1>
                        <div className="row">
                            {/* Fakultas Column */}
                            <div className="col-lg-6">
                                <h5>Prospek Kerja Poltekim</h5>
                                <ul>
                                    <li>Unit Pelayanan teknis Dirjen Pemasyarakatan</li>
                                    <li>Unit pelaksanaan teknis Dirjen Imigrasi</li>
                                    <li>Lembaga Pemasyarakatan</li>
                                    <li>Kantor Imigrasi Bandara dl.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="row">
                            {/* Program Studi Column POLTEKIM */}
                            <div className="col-lg-6">
                                <h5>Program Studi POLTEKIM</h5>
                                <ul>
                                    <li>D4 – Manajemen Teknologi Keimigrasian</li>
                                    <li>D4 - Adminitrasi Keimigrasian</li>
                                    <li>D4 - Hukum Keimigrasian</li>
                                    <li>D3 - Keimigrasian</li>
                                </ul>
                            </div>
                        </div>
                    </div>                    {/* Section with title above ProjectSekdinIPDN */}
                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Alumni DELTA lolos POLTEKIM</h3>
                    <div style={{ marginTop: '30px' }}>
                        <ProjectSekdinIPDN />
                    </div>
                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Sekolah Kedinasan Lain :</h3>
                    <div style={{ marginTop: '30px' }}><PartnerSection /></div>
                         <div className="text-center mt-5"> 
                             <Link
                                 to="/" 
                                 className="btn btn-primary"
                                 onClick={ClickHandler}
                                 style={{ display: 'inline-block' }} // Ensure the button is inline-block for centering
                             >
                                 <span className="btn_label" data-text="Kembali">Kembali</span>
                                 <span className="btn_icon">
                                     <i className="fa-solid fa-arrow-up-right"></i>
                                 </span>
                             </Link>
                    </div>                   
                </section>
            </main>
            <Footer />
            <WhatsappButton />
        </Fragment>
    );
};

export default SekdinPage;
