import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import aImg from '../../images/artikelsekdin/stin1.jpeg';
import aImg2 from '../../images/artikelsekdin/stin2.jpg';
import aImg3 from '../../images/artikelsekdin/stin3.jpg';
import aImg4 from '../../images/artikelsekdin/stin4.jpg';
import WhatsappButton from '../../components/WhatsappButton/whatsappbutton';

const SekdinPage = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Sekolah Tinggi Intelijen Negara (STIN)'} />
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="STIN - About" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="STIN - About" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* New Section for STIN Information */}
                <section className="about-info-section bg-white py-5">
                    <div className="container">
                        <h1 className="section-title mb-4">Tentang STIN</h1>
                        <p style={{ textAlign: 'justify', marginBottom: '10px' }}>
                            Sekolah Tinggi Intelijen Negara (STIN) adalah perguruan tinggi kedinasan di bawah Badan Intelijen Negara (BIN) yang berfokus pada pendidikan di bidang intelijen. STIN bertujuan untuk menghasilkan sumber daya manusia yang profesional dalam bidang intelijen guna mendukung keamanan dan kedaulatan negara. Lulusan dari STIN diharapkan dapat menjadi anggota yang kompeten dalam dunia intelijen untuk menjaga keamanan negara.
                        </p>
                        <p style={{ textAlign: 'justify', marginBottom: '40px' }}>
                            STIN menyediakan berbagai program studi yang meliputi analisis intelijen, teknologi intelijen, dan ekonomi intelijen. STIN memiliki proses seleksi yang ketat untuk memastikan bahwa hanya peserta terbaik yang dapat diterima. Lulusan STIN berpeluang besar untuk bergabung dengan Badan Intelijen Negara dan instansi pemerintah lainnya.
                        </p>
                        
                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg3} alt="STIN Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Kampus STIN - Mencetak Profesional di Bidang Intelijen</p>
                        </div>

                        <h1 className="section-title mt-0">Persyaratan Pendaftaran STIN</h1>
                        <ol>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Umum</h5>
                                <li>Lulusan SMA / MA / SMK</li>
                                <li>Kualifikasi Tinggi & Berat badan :</li>
                                <li>Pria minimal 170 cm, Wanita 160 cm, Berat Badan ideal</li>
                                <li>Mata minus / plus / silinder tidak diperbolehkan</li>
                                <li>Buta warna parsial maupun total tidak diperbolehkan</li>
                                <li>Usia max. 23 tahun & min. 17 tahun per 1 April 2024</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Akademik</h5>
                                <li>Nilai rata rata ijazah minimal 80 bagi lulusan tahun sebelumnya</li>
                                <li>Nilai rata rata rapor semester 1-5 minimal 75, bagi lulusan tahun pendaftaran</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Berkas</h5>
                                <li>Surat ijin orang tua/wali, dapat diunduh di ptb.stin.ac.id.</li>
                                <li>Ijazah SMA/SMK/MA untuk lulusan tahun sebelumnya dalam bentuk pdf</li>
                                <li>Surat keterangan lulus dari sekolah bagi lulusan tahun saat pendaftaran .</li>
                                <li>Foto berwarna seluruh badan terbaru, tampak depan, ukuran postcard (3R), Memakai pakaian berwarna putih & bawahan hitam.</li>
                                <li>Latar belakang foto merah bagi pria, biru bagi perempuan.</li>
                                <li>Foto Copy Kartu Keluarga</li>
                        </ol>
                        <h1 className="marginBottom:'10px'">Tahapan Test Masuk PKN STAN</h1>
                            <h5>Tahap 1: Seleksi Administrasi</h5>
                            <ul>
                                <li>Pemberkasan Administrasi</li>
                            </ul>
                            <h5>Tahap 2: Seleksi SKD</h5>
                            <ul>
                            <li>Test Karakteristik Pribadi (TKP)</li>
                            <li>Test Intelegensi Umum (TIU)</li>
                            <li>Test Wawasan Kebangsaan (TWK)</li>
                            </ul>
                            <h5>Tahap 3: Seleksi Akhir Daerah</h5>
                            <ul>
                                <li>Tes Kesehatan Fisik</li>
                                <li>Tes Psikotes</li>
                                <li>Test Kesehatan Jiwa</li>
                                <li>Test Kesamaptaan</li>
                                <li>Test Ideologi dan Jasmani</li>
                            </ul>
                            <h5>Tahap 4: Seleksi Akhir Pusat</h5>
                            <ul>
                                <li>Test Kesamaptaan Jasmani</li>
                                <li>Test Mental Ideologi</li>
                            </ul>
                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg4} alt="STIN Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Kampus STIN - Mencetak Profesional di Bidang Intelijen</p>
                        </div>

                        <h1 className="marginBottom:'10px'">Fakultas dan Program Studi STIN</h1>
                        <div className="row">
                            {/* Fakultas Column */}
                            <div className="col-lg-6">
                                <h5>Fakultas</h5>
                                <ul>
                                    <li>Fakultas Intelijen</li>
                                </ul>
                            </div>
                            {/* Program Studi Column */}
                            <div className="col-lg-6">
                                <h5>Program Studi</h5>
                                <ul>
                                    <li>S1 – Analisis Intelijen</li>
                                    <li>S1 – Teknologi Intelijen</li>
                                    <li>S1 – Ekonomi Intelijen</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Sekolah Kedinasan Lain :</h3>
                    <div style={{ marginTop: '30px' }}><PartnerSection /></div>
                         <div className="text-center mt-5"> 
                             <Link
                                 to="/" 
                                 className="btn btn-primary"
                                 onClick={ClickHandler}
                                 style={{ display: 'inline-block' }} // Ensure the button is inline-block for centering
                             >
                                 <span className="btn_label" data-text="Kembali">Kembali</span>
                                 <span className="btn_icon">
                                     <i className="fa-solid fa-arrow-up-right"></i>
                                 </span>
                             </Link>       
                    </div>
                </section>
            </main>
            <Footer />
            <WhatsappButton />
        </Fragment>
    );
};

export default SekdinPage;
