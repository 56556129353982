import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import ProjectSekdinIPDN from '../../components/ProjectSection/ProjectSectionSTAN';
import aImg from '../../images/artikelsekdin/stan1.jpg';
import aImg2 from '../../images/artikelsekdin/stan2.jpg';
import aImg3 from '../../images/artikelsekdin/stan3.jpg';
import aImg4 from '../../images/artikelsekdin/stan4.jpg';
import WhatsappButton from '../../components/WhatsappButton/whatsappbutton';

const SekdinPage = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Politeknik Keuangan Negara STAN (PKN STAN)'} />
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="PKN STAN - About" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="PKN STAN - About" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* New Section for PKN STAN Information */}
                <section className="about-info-section bg-white py-5">
                    <div className="container">
                        <h1 className="section-title mb-4">Tentang PKN STAN</h1>
                        <p style={{ textAlign: 'justify', marginBottom: '10px' }}>
                            Politeknik Keuangan Negara STAN (PKN STAN) adalah perguruan tinggi kedinasan yang berada di bawah Kementerian Keuangan Republik Indonesia. PKN STAN fokus pada pendidikan vokasi di bidang keuangan negara dan bertujuan menghasilkan tenaga profesional yang kompeten dalam pengelolaan keuangan publik. PKN STAN menawarkan berbagai program studi yang didesain untuk memenuhi kebutuhan tenaga ahli di bidang keuangan negara.
                        </p>
                        <p style={{ textAlign: 'justify', marginBottom: '40px' }}>
                            PKN STAN dikenal sebagai sekolah kedinasan favorit bagi lulusan SMA karena berbagai benefitnya, seperti kuliah gratis dan peluang menjadi CPNS (Calon Pegawai Negeri Sipil) setelah lulus. Setelah lulus dari PKN STAN, mahasiswa dapat bekerja di berbagai instansi pemerintah, termasuk di Kementerian Keuangan, Badan Pemeriksa Keuangan (BPK), dan lembaga-lembaga lainnya.
                        </p>
                        
                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg3} alt="PKN STAN Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Mahasiswa PKN STAN didepan Air Mancur STAN</p>
                        </div>

                        <h1 className="section-title mt-0">Persyaratan Pendaftaran PKN STAN</h1>
                        <ol>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Umum</h5>
                                <li>Lulusan SMA/MA/SMK atau Semua Pendidikan tingkat atas</li>
                                <li>Tidak ada kualifikasi tinggi dan berat badan</li>
                                <li>Tidak ada batas mata minus plus atau silinder</li>
                                <li>Tidak buta warna baik parsial maupun total</li>
                                <li>Usia max. 21 tahun dan min. 16 tahun per 1 September di tahun pendaftaran</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Akademik</h5>
                                <li>Lulusan SMA/SMK/MA dari semua jurusan pada tahun pendaftaran atau satu tahun sebelumnya</li>
                                <li>Memiliki rata-rata nilai rapor semester 1–5 minimal 70,00 dengan skala 100,00</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Berkas</h5>
                                <li>Pas foto berlatar belakang merah, mengenakan kemeja putih polos, dan bagi peserta wanita yang berjilbab mengenakan jilbab warna putih polos.</li>
                                <li>KTP bagi peserta yang berusia 17 tahun ke atas Kartu Keluarga (KK) bagi yang belum memiliki KTP atau Surat Keterangan Pengganti KTP.</li>
                                <li>Kartu Peserta UTBK-SNBT.</li>
                                <li>Rapor SMA (semester gasal dan genap untuk kelas X dan XI serta semester gasal kelas XII) ditambah Ijazah SD/yang sederajat &amp; Ijazah SMP/sederajat bagi calon lulusan tahun 2024.</li>
                                <li>Ijazah SMA ditambah Ijazah SD/yang sederajat &amp; Ijazah SMP/sederajat bagi lulusan tahun sebelumnya</li>
                        </ol>

                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg4} alt="PKN STAN Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Gedung Baru PKN STAN</p>
                        </div>

                        <h1 className="marginBottom:'10px'">Tahapan Test Masuk PKN STAN</h1>
                            <h5>Tahap 1: Seleksi Kompetensi Dasar</h5>
                            <ul>
                                <li>Tes Karakteristik Pribadi (TKP)</li>
                                <li>Tes Intelegensi Umum (TIU)</li>
                                <li>Tes Wawasan Kebangsaan (TWK)</li>
                            </ul>
                            <h5>Tahap 2: Seleksi TPA</h5>
                            <ul>
                                <li>Test Potensi Akademik</li>
                                <li>Test Bahasa Inggris</li>
                            </ul>
                            <h5>Tahap 3: Tes Lanjutan 1</h5>
                            <ul>
                                <li>Tes Kesehatan & Kebugaran (TKK)</li>
                                <li>Tes Psikotes</li>
                            </ul>
                            <h5>Tahap 4: Tes Lanjutan 2</h5>
                            <ul>
                                <li>Tes Wawancara</li>
                            </ul>
                            <h1 className="marginBottom:'10px'">Penempatan Kerja dan Program Studi PKN STAN</h1>
                        <div className="row">
                            {/* Fakultas Column */}
                            <div className="col-lg-6">
                                <h5>Penempatan Kerja</h5>
                                <ul>
                                    <li>Direktorat Jendral Pajak</li>
                                    <li>Direktorat Jenderal Beacukai</li>
                                    <li>Lembaga Administrasi Negara</li>
                                    <li>Lembaga Kebijakan Pengadaan Barang dan Jasa</li>
                                    <li>Badan Pemerintahan Daerah atau Kementerian Lain</li>
                                </ul>
                            </div>
                            {/* Program Studi Column */}
                            <div className="col-lg-6">
                                <h5>Program Studi</h5>
                                <ul>
                                    <li>D4 – Akuntansi Sektor Publik</li>
                                    <li>D4 – Manajemen Keuangan Negara</li>
                                    <li>D4 – Manajemen Aset Publik</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                    {/* Section with title above ProjectSekdinIPDN */}
                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Alumni DELTA lolos PKN STAN</h3>
                    <div style={{ marginTop: '30px' }}>
                        <ProjectSekdinIPDN />
                    </div>
                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Sekolah Kedinasan Lain :</h3>
                    <div style={{ marginTop: '30px' }}><PartnerSection /></div>
                         <div className="text-center mt-5"> 
                             <Link
                                 to="/" 
                                 className="btn btn-primary"
                                 onClick={ClickHandler}
                                 style={{ display: 'inline-block' }} // Ensure the button is inline-block for centering
                             >
                                 <span className="btn_label" data-text="Kembali">Kembali</span>
                                 <span className="btn_icon">
                                     <i className="fa-solid fa-arrow-up-right"></i>
                                 </span>
                             </Link>       
                    </div>
            </main>
            <Footer />
            <WhatsappButton />
        </Fragment>
    );
};

export default SekdinPage;
