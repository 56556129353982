import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.scss";
import pimg1 from '../../images/clients/client_logo_1.webp'
import pimg2 from '../../images/clients/client_logo_2.webp'
import pimg3 from '../../images/clients/client_logo_3.webp'
import pimg4 from '../../images/clients/client_logo_4.webp'
import pimg5 from '../../images/clients/client_logo_5.webp'
import pimg6 from '../../images/clients/client_logo_6.webp'
import pimg7 from '../../images/clients/client_logo_7.webp'
import pimg8 from '../../images/clients/client_logo_8.webp'

const partners = [
    {
        pImg: pimg1,
        pagelink: "stmkg"
    },
    {
        pImg: pimg2,
        pagelink: "stis" 
    },
    {
        pImg: pimg3,
        pagelink: "stan"
    },
    {
        pImg: pimg4,
        pagelink: "kemenhub"
    },
    {
        pImg: pimg5,
        pagelink: "ipdn"
    },
    {
        pImg: pimg6,
        pagelink: "stin"
    },
    {
        pImg: pimg7,
        pagelink: "poltekip"
    },
    {
        pImg: pimg8,
        pagelink: "poltekim"
    },
];

const settings = {
    dots: true,
    filtered: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: true,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 350,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const PartnerSection = (props) => {
    const handlePartnerClick = (partner, index) => {
        if (partner.pagelink) {
            window.location.href = partner.pagelink;
        }
    };

    return (
        <div className="client_logo_carousel">
            <Slider {...settings}>
                {partners.map((partner, pitem) => (
                    <div 
                        className="client_logo_item" 
                        key={pitem}
                        onClick={() => handlePartnerClick(partner, pitem)}
                        style={{ cursor: 'pointer', pointerEvents: 'all' }}
                    >
                        <img 
                            src={partner.pImg} 
                            alt="Techco - Client Logo" 
                            style={{ cursor: 'pointer', pointerEvents: 'all' }}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default PartnerSection;