import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage/HomePage'
import HomePage2 from '../HomePage2/HomePage2';
import HomePage3 from '../HomePage3/HomePage3';
import AboutUsPage from '../AboutUsPage/AboutUsPage';
import PricingPage from '../PricingPage/PricingPage';
import Alumni from '../Alumni/Alumni';
import TeamPage from '../TeamPage/TeamPage';
import ServiceSinglePage from '../ServiceSinglePage/ServiceSinglePage';
import ContactPage from '../ContactPage/ContactPage';
import IPDN from '../Sekdin/ipdn'
import STAN from '../Sekdin/stan'
import Kemenhub from '../Sekdin/kemenhub'
import Poltekim from '../Sekdin/poltekim'
import Poltekip from '../Sekdin/poltekip'
import SSN from '../Sekdin/ssn'
import STIN from '../Sekdin/stin'
import STIS from '../Sekdin/stis'
import STMKG from '../Sekdin/stmkg'

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="home_software_company" element={<HomePage2 />} />
          <Route path="home_business_consulting" element={<HomePage3 />} />
          <Route path="about" element={<AboutUsPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="Alumni" element={<Alumni />} />
          <Route path="tentor" element={<TeamPage />} />
          <Route path="service-single/:slug" element={<ServiceSinglePage />} />
          <Route path="contact" element={<ContactPage />} />  
          <Route path="ipdn" element={<IPDN />} />
          <Route path="stan" element={<STAN />} />
          <Route path="kemenhub" element={<Kemenhub />} />
          <Route path="poltekim" element={<Poltekim />} />
          <Route path="poltekip" element={<Poltekip />} />
          <Route path="ssn" element={<SSN />} />
          <Route path="stin" element={<STIN />} />
          <Route path="stis" element={<STIS />} />
          <Route path="stmkg" element={<STMKG />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
