import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import ProjectSekdinIPDN from '../../components/ProjectSection/ProjectSectionKEMENHUB';
import aImg from '../../images/artikelsekdin/kemenhub1.jpg';
import aImg2 from '../../images/artikelsekdin/kemenhub2.jpg';
import aImg3 from '../../images/artikelsekdin/kemenhub3.png';
import aImg4 from '../../images/artikelsekdin/kemenhub4.png';
import WhatsappButton from '../../components/WhatsappButton/whatsappbutton';

const SekdinPage = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Sekolah Kedinasan Kemenhub'} />
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="Techco - About" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="Techco - About" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* New Section for IPDN Information */}
                <section className="about-info-section bg-white py-5">
                    <div className="container">
                        <h1 className="section-title mb-4">Tentang Sekdin Kemenhub</h1>
                        <p style={{ textAlign: 'justify' , marginBottom: '10px' }}>
                        Adalah sekolah tinggi yang bernaung di bawah kementrian perhubungan Indonesia ada 3  yang kita sebut matra dalam sekdin kemenhun yaitu matra darat,lau,dan udara untuk sekolah nya sendiri ada 24 sekolah kedinasan yang di bawah kementrian perhubungan,24 sekolah yang kita sebut 3 matra, Badan Pengembangan Sumber Daya Manusia Perhubungan (BPSDM Perhubungan) adalah instansi pemerintahan yang berada di bawah Kementerian Perhubungan yang bertugas untuk menyiapkan sumber daya manusia di bidang transportasi dalam meningkatkan produktivitas, efisiensi, dan kualitas pelayanan transportasi darat, laut dan udara yang unggul, profesional dan berkualitas
                        </p>
                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg3} alt="IPDN Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Kampus IPDN - Tempat Mendidik Pemimpin Masa Depan</p> {/* Margin top untuk subheading */}
                        </div>

                        <h1 className="section-title mt-0">Persyaratan Sekdin Kemenhub</h1>
                        <ol>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Umum</h5> {/* Margin top untuk Persyaratan Umum */}
                                <li>Warga Negara Indonesia</li>
                                <li>Usia Peserta Seleksi minimal 16 tahun dan maksimal 21 tahun per tanggal 1 Januari 2025</li>
                                <li>Tinggi Badan Pendaftar Pria minimal 160 cm dan wanita minimal 155 cm</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Administrasi</h5> {/* Margin top untuk Persyaratan Administrasi */}
                                <li>Berijazah Sekolah Menengah Atas (SMA) atau Madrasah Aliyah (MA) termasuk lulusan Paket C, bagi lulusan Tahun 2022 – 2025 dengan Nilai Rata Rata Ijazah minimal 70,00 atau 65,00 untuk pendaftar Jalur Afirmasi Papua</li>
                                <li>Berdomisili minimal 1 (satu) tahun di Kabupaten/Kota pada Provinsi tempat mendaftar secara sah terhitung pada tanggal awal pendaftaran yang dibuktikan dengan KTP-el, Kartu Keluarga dan Surat Pindah (bagi yang pindah tempat tinggal) serta dokumen lain yang berhubungan dengan domisili, dikecualikan bagi orang tua (Bapak/Ibu Kandung) peserta yang lahir di tempat pendaftaran dibuktikan dengan akta kelahiran orang tua dan/atau surat penempatan pindah tugas orang tua dari instansi masing-masing. Apabila terbukti melakukan duplikasi/pemalsuan/rekayasa keterangan akan ditindak sesuai dengan peraturan perundang-undangan yang berlaku;</li>
                                <li>Surat Keterangan Kelas XII SMA/MA yang ditandatangani oleh Kepala Sekolah atau pejabat yang berwenang dan dicap/distempel basah, bagi siswa SMA/MA lulusan Tahun ini untuk dokumen awal persyaratan pendaftaran;</li>
                                <li>Surat Keterangan Orang Asli Papua (OAP) khusus bagi peserta OAP ditandatangani oleh Ketua atau Anggota Majelis Rakyat Papua berdasarkan keanggotaan yang sah sesuai dengan ketentuan peraturan perundang-undangan yang berlaku dan diketahui oleh Kepala Distrik pada Kabupaten / Kota pendaftaran, yang dibuktikan dengan cap/stempel basah;</li>
                                <li>Pakta Integritas Tahun ini;</li>
                                <li>Alamat e-mail yang aktif; </li>
                                <li>Pakta Integritas Tahun ini;</li>
                                <li>Alamat e-mail yang aktif; dan </li>
                                <li>Pasfoto berwarna ukuran foto 4x6 cm dengan menghadap ke depan dan tidak memakai kacamata, serta mengenakan kemeja lengan panjang berwarna putih polos dengan latar belakang merah.</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Khusus</h5> {/* Margin top untuk Persyaratan Khusus */}
                                <li>Tidak sedang menjalani atau terancam hukuman pidana karena melakukan kejahatan;</li>
                                <li>Tidak bertindik atau bekas ditindik telinganya atau anggota badan lainnya bagi peserta pria, kecuali karena ketentuan agama/adat;</li>
                                <li>Tidak bertato;</li>
                                <li>Tidak menggunakan kacamata/lensa kontak;</li>
                                <li>Belum pernah menikah/kawin, bagi pendaftar wanita belum pernah hamil/ melahirkan;</li>
                                <li>Belum pernah diberhentikan sebagai Praja IPDN dan perguruan tinggi lainnya dengan tidak hormat;</li>
                        </ol>
                            {/* New Image and Subheading */}
                            <div className="text-center mb-4">
                            <img src={aImg4} alt="IPDN Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Kampus IPDN - Tempat Mendidik Pemimpin Masa Depan</p> {/* Margin top untuk subheading */}
                        </div>
                        <h1 className="marginBottom:'10px'"> Daftar Sekolah Kedinasan Kemenhub</h1>
                        <div className="row">
                            {/* Sekdin Matra Darat */}
                            <div className="col-lg-6">
                                <h5>Sekdin Kemenhub Matra Darat</h5>
                                <ul>
                                <li>Politeknik Transportasi Darat Indonesia (PTDI) STTD</li>
                                <li>Politeknik Keselamatan Transportasi Jalan (PKTJ)</li>
                                <li>Politeknik Perkeretaapian Indonesia (PPI) Madiun</li>
                                <li>Politeknik Transportasi Darat (POLTRADA) Bali</li>
                                <li>Politeknik Transportasi Sungai, Danau, dan Penyeberangan (POLTEKTRANS – SDP) Palembang</li>
                                </ul>
                            </div>
                            {/* Sekdin Matra Laut */}
                            <div className="col-lg-6">
                                <h5>Sekdin Kemenhub Matra Laut</h5>
                                <ul>
                                <li>Balai Besar Pendidikan Penyegaran dan Peningkatan Ilmu Pelayaran (BP3IP) Jakarta</li>
                                <li>Politeknik Ilmu Pelayaran (PIP) Semarang</li>
                                <li>Politeknik Ilmu Pelayaran (PIP) Makassar</li>
                                <li>Politeknik Pelayaran (POLTEKPEL) Sorong</li>
                                <li>Politeknik Pelayaran (POLTEKPEL) Sulawesi Utara</li>
                                <li>Politeknik Pelayaran (POLTEKPEL) Banten</li>
                                <li>Politeknik Pelayaran (POLTEKPEL) Barombong</li>
                                <li>Politeknik Pelayaran (POLTEKPEL) Malahayati</li>
                                <li>Politeknik Pelayaran (POLTEKPEL) Sumatera Barat</li>
                                <li>Politeknik Pelayaran (POLTEKPEL) Surabaya</li>
                                <li>Sekolah Tinggi Ilmu Pelayaran (STIP) Jakarta</li>
                                </ul>
                            </div>
                             {/* Sekdin Matra Udara */}
                             <div className="col-lg-6">
                                <h5>Sekdin Kemenhub Matra Laut</h5>
                                <ul>
                                <li>Akademi Penerbang Indonesia (API) Banyuwangi</li>
                                <li>Politeknik Penerbangan Indonesia (PPI) Curug</li>
                                <li>Politeknik Penerbangan (POLTEKBANG) Surabaya</li>
                                <li>Politeknik Penerbangan (POLTEKBANG) Palembang</li>
                                <li>Politeknik Penerbangan (POLTEKBANG) Medan</li>
                                <li>Politeknik Penerbangan (POLTEKBANG) Makassar</li>
                                <li>Politeknik Penerbangan (POLTEKBANG) Jayapura</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Section with title above ProjectSekdinIPDN */}
                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Alumni DELTA lolos KEMENHUB</h3>
                    <div style={{ marginTop: '30px' }}>
                        <ProjectSekdinIPDN />
                    </div>

                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Sekolah Kedinasan Lain :</h3>
                    <div style={{ marginTop: '30px' }}><PartnerSection /></div>
                         <div className="text-center mt-5"> 
                             <Link
                                 to="/" 
                                 className="btn btn-primary"
                                 onClick={ClickHandler}
                                 style={{ display: 'inline-block' }} // Ensure the button is inline-block for centering
                             >
                                 <span className="btn_label" data-text="Kembali">Kembali</span>
                                 <span className="btn_icon">
                                     <i className="fa-solid fa-arrow-up-right"></i>
                                 </span>
                             </Link>
                         </div>                   
                </section>
            </main>
            <Footer />
            <WhatsappButton />
        </Fragment>
    );
};

export default SekdinPage;
