import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import ProjectSekdinIPDN from '../../components/ProjectSection/ProjectSectionSTIS';
import aImg from '../../images/artikelsekdin/stis1.jpeg';
import aImg2 from '../../images/artikelsekdin/stis2.jpg';
import aImg3 from '../../images/artikelsekdin/stis3.jpg';
import aImg4 from '../../images/artikelsekdin/stis4.jpg';
import WhatsappButton from '../../components/WhatsappButton/whatsappbutton';

const SekdinPage = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Politeknik Statistika STIS (Polstat STIS)'} />
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="Polstat STIS - About" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="Polstat STIS - About" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* New Section for Polstat STIS Information */}
                <section className="about-info-section bg-white py-5">
                    <div className="container">
                        <h1 className="section-title mb-4">Tentang Polstat STIS</h1>
                        <p style={{ textAlign: 'justify', marginBottom: '10px' }}>
                            Politeknik Statistika STIS (Polstat STIS) adalah perguruan tinggi kedinasan di bawah Badan Pusat Statistik (BPS) yang berfokus pada pendidikan vokasi di bidang statistika. Polstat STIS bertujuan menghasilkan tenaga profesional yang terampil dalam melakukan pengolahan dan analisis data statistik untuk mendukung kebijakan publik. Lulusan dari Polstat STIS siap bekerja di BPS dan instansi pemerintah lainnya.
                        </p>
                        <p style={{ textAlign: 'justify', marginBottom: '40px' }}>
                            Polstat STIS menawarkan program studi yang meliputi D3 Statistika dan D4 Statistika dengan peminatan di berbagai bidang analisis data dan sains data. Selain itu, Polstat STIS juga memiliki jalur seleksi yang sangat kompetitif, memberikan kesempatan bagi siswa terbaik untuk menempuh pendidikan gratis dengan potensi menjadi PNS setelah lulus.
                        </p>
                        
                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg3} alt="Polstat STIS Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Mahasiswa Polstat STIS</p>
                        </div>

                        <h1 className="section-title mt-0">Persyaratan Pendaftaran Polstat STIS</h1>
                        <ol>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Umum</h5>
                                <li>Lulusan SMA / MA / SMK peminatan Teknik Komputer & Informatika</li>
                                <li>Tidak ada kualifikasi Tinggi & Berat badan</li>
                                <li>Batas toleransi Mata minus / plus dibawah 6 Dioptri</li>
                                <li>Tidak buta warna parsial maupun total</li>
                                <li>Usia max. 22 tahun & min. 16 tahun per 1 September di tahun pendaftaran</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Akademik</h5>
                                <li>Nilai Matematika (Kelompok A / Umum) dan Bahasa Inggris minimal 80,00 (jka skala nilai 1 s.d. 100) atau 3,20 (skala 1,00 s.d. 4,00 pada Ijazah atau nilai rapor semester gasal kelas 12.</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Berkas</h5>
                                <li>Pas foto Kartu Identitas, bisa berupa KTP / KIA</li>
                                <li>Kartu Keluarga (KK)</li>
                                <li>Akta Kelahiran</li>
                                <li>Ijazah serat Transkip Nilai / Rapor kelas 12 Semester 1 - 5 untuk siswa kelas 12</li>
                                <li>Surat keterangan orang Papua asli untuk program afirmasi</li>
                        </ol>

                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg4} alt="Polstat STIS Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Wisuda Polstat STIS</p>
                        </div>
                        <h1 className="marginBottom:'10px'">Tahapan Test Masuk STIS</h1>
                            <h5>Tahap 1: Seleksi Tahap 1</h5>
                            <ul>
                                <li>Seleksi administrasi yang terdiri dari pendaftaran SSCASN, Pemberkasan dan Verifikasi berkas pendaftaran</li>
                            </ul>
                            <h5>Tahap 2: Seleksi SKD</h5>
                            <ul>
                                <li>Test Karakteristik Pribadi (TKP)</li>
                                <li>Test Intelegensi Umum (TIU)</li>
                                <li>Test Wawasan Kebangsaan (TWK)</li>
                            </ul>
                            <h5>Tahap 3: Tes Lanjutan 1</h5>
                            <ul>
                                <li>Tes Akademik Matematika</li>
                                <li>Tes Psikotes</li>
                            </ul>
                            <h5>Tahap 4: Tes Lanjutan 2</h5>
                            <ul>
                                <li>Tes Kesehatan dan Kebugaran</li>
                            </ul>
                        <h1 className="marginBottom:'10px'">Penempatan Kerja dan Program Studi Polstat STIS</h1>
                        <div className="row">
                            {/* Fakultas Column */}
                            <div className="col-lg-6">
                                <h5>Penempatan Kerja</h5>
                                <ul>
                                    <li>Badan Pusat Statistik tingkat Pusat</li>
                                    <li>Badan Pusat Statistik tingkat Provinsi</li>
                                    <li>Badan Pusat Statistik tingkat Kota/Kabupaten</li>
                                    <li>Lembaga lain yang membutuhkan Lulusan STIS</li>
                                </ul>
                            </div>
                            {/* Program Studi Column */}
                            <div className="col-lg-6">
                                <h5>Program Studi</h5>
                                <ul>
                                    <li>D3 – Statistika</li>
                                    <li>D4 – Statistika</li>
                                    <li>D4 – Komputasi Statistik</li>
                                </ul>
                            </div>
                        </div>
                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Alumni DELTA lolos POLSTAT STIS</h3>
                    <div style={{ marginTop: '30px' }}>
                        <ProjectSekdinIPDN />
                    </div>
                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Sekolah Kedinasan Lain :</h3>
                    <div style={{ marginTop: '30px' }}><PartnerSection /></div>
                         <div className="text-center mt-5"> 
                             <Link
                                 to="/" 
                                 className="btn btn-primary"
                                 onClick={ClickHandler}
                                 style={{ display: 'inline-block' }} // Ensure the button is inline-block for centering
                             >
                                 <span className="btn_label" data-text="Kembali">Kembali</span>
                                 <span className="btn_icon">
                                     <i className="fa-solid fa-arrow-up-right"></i>
                                 </span>
                             </Link>      
                    </div>
                    </div>
                </section>
            </main>
            <Footer />
            <WhatsappButton />
        </Fragment>
    );
};

export default SekdinPage;
