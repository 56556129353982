import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import ProjectSekdinIPDN from '../../components/ProjectSection/ProjectSectionIPDN';
import aImg from '../../images/artikelsekdin/ipdn1.jpeg';
import aImg2 from '../../images/artikelsekdin/ipdn2.jpg';
import aImg3 from '../../images/artikelsekdin/ipdn3.jpg';
import aImg4 from '../../images/artikelsekdin/ipdn4.jpg';
import WhatsappButton from '../../components/WhatsappButton/whatsappbutton';

const SekdinPage = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Institut Pemerintahan Dalam Negeri'} />
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="Techco - About" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="Techco - About" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* New Section for IPDN Information */}
                <section className="about-info-section bg-white py-5">
                    <div className="container">
                        <h1 className="section-title mb-4">Tentang IPDN</h1>
                        <p style={{ textAlign: 'justify', marginBottom: '10px' }}>
                            Institut Pemerintahan Dalam Negeri (IPDN) adalah perguruan tinggi kedinasan yang
                            berfokus pada pendidikan untuk menghasilkan calon pemimpin yang profesional di bidang
                            pemerintahan. IPDN merupakan lembaga pendidikan yang mencetak calon pegawai negeri
                            sipil yang siap melayani masyarakat dan negara dengan integritas dan kompetensi tinggi.
                            Dengan kurikulum yang berbasis pada pelayanan publik dan pemerintahan, IPDN mempersiapkan
                            mahasiswanya untuk berkontribusi langsung dalam pembangunan bangsa.
                        </p>
                        <p style={{ textAlign: 'justify', marginBottom: '40px' }}>
                            IPDN juga menjadi salah satu sekolah kedinasan favorit lulusan SMA karena sejumlah benefitnya. Seperti kuliah gratis dan bisa menjadi CPNS (Calon Pegawai Negeri Sipil) setelah lulus. Setelah lulus dari IPDN, kamu bisa bekerja di lingkungan birokrasi pemerintahan. Mulai dari merintis karier dari staf pemerintahan, camat hingga bisa menjabat sebagai seorang Gubernur. Banyak juga siswa siswi Bimbel delta kami yang lolos IPDN karena itu kami ngeajak kalian semua untuk mengetahui seberapa penting nya kalian tau sekolah kedinasan tida hanya IPDN saja masih banyak yang lain nya sekolah sekolah kedinasan yang harus kalian tau
                        </p>

                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg3} alt="IPDN Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Taruna IPDN sedang melaksanakan pendidikan</p>
                        </div>

                        <h1 className="section-title mt-0">Persyaratan IPDN</h1>
                        <ol>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Umum</h5>
                            <li>Warga Negara Indonesia</li>
                            <li>Usia Peserta Seleksi minimal 16 tahun dan maksimal 21 tahun per tanggal 1 Januari 2025</li>
                            <li>Tinggi Badan Pendaftar Pria minimal 160 cm dan wanita minimal 155 cm</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Administrasi</h5>
                            <li>Berijazah Sekolah Menengah Atas (SMA) atau Madrasah Aliyah (MA) termasuk lulusan Paket C, bagi lulusan Tahun 2022 – 2025 dengan Nilai Rata Rata Ijazah minimal 70,00 atau 65,00 untuk pendaftar Jalur Afirmasi Papua</li>
                            <li>Berdomisili minimal 1 (satu) tahun di Kabupaten/Kota pada Provinsi tempat mendaftar secara sah terhitung pada tanggal awal pendaftaran yang dibuktikan dengan KTP-el, Kartu Keluarga dan Surat Pindah (bagi yang pindah tempat tinggal) serta dokumen lain yang berhubungan dengan domisili, dikecualikan bagi orang tua (Bapak/Ibu Kandung) peserta yang lahir di tempat pendaftaran dibuktikan dengan akta kelahiran orang tua dan/atau surat penempatan pindah tugas orang tua dari instansi masing-masing. Apabila terbukti melakukan duplikasi/pemalsuan/rekayasa keterangan akan ditindak sesuai dengan peraturan perundang-undangan yang berlaku;</li>
                            <li>Surat Keterangan Kelas XII SMA/MA yang ditandatangani oleh Kepala Sekolah atau pejabat yang berwenang dan dicap/distempel basah, bagi siswa SMA/MA lulusan Tahun ini untuk dokumen awal persyaratan pendaftaran;</li>
                            <li>Surat Keterangan Orang Asli Papua (OAP) khusus bagi peserta OAP ditandatangani oleh Ketua atau Anggota Majelis Rakyat Papua berdasarkan keanggotaan yang sah sesuai dengan ketentuan peraturan perundang-undangan yang berlaku dan diketahui oleh Kepala Distrik pada Kabupaten / Kota pendaftaran, yang dibuktikan dengan cap/stempel basah;</li>
                            <li>Pakta Integritas Tahun ini;</li>
                            <li>Alamat e-mail yang aktif;</li>
                            <li>Pasfoto berwarna ukuran foto 4x6 cm dengan menghadap ke depan dan tidak memakai kacamata, serta mengenakan kemeja lengan panjang berwarna putih polos dengan latar belakang merah.</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Khusus</h5>
                            <li>Tidak sedang menjalani atau terancam hukuman pidana karena melakukan kejahatan;</li>
                            <li>Tidak bertindik atau bekas ditindik telinganya atau anggota badan lainnya bagi peserta pria, kecuali karena ketentuan agama/adat;</li>
                            <li>Tidak bertato;</li>
                            <li>Tidak menggunakan kacamata/lensa kontak;</li>
                            <li>Belum pernah menikah/kawin, bagi pendaftar wanita belum pernah hamil/ melahirkan;</li>
                            <li>Belum pernah diberhentikan sebagai Praja IPDN dan perguruan tinggi lainnya dengan tidak hormat;</li>
                        </ol>

                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg4} alt="IPDN Campus" className="rounded-rect" style={{ maxWidth: '70%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Upacara Apel IPDN</p>
                        </div>

                        <h1 className="mt-0">Tahapan Test Masuk IPDN</h1>
                        <h5>Tahap 1: Seleksi Tahap 1</h5>
                        <ul>
                            <li>Seleksi administrasi yang terdiri dari pendaftaran SSCASN, Pemberkasan dan Verifikasi berkas pendaftaran</li>
                        </ul>
                        <h5>Tahap 2: Seleksi SKD</h5>
                        <ul>
                            <li>Test Karakteristik Pribadi (TKP)</li>
                            <li>Test Intelegensi Umum (TIU)</li>
                            <li>Test Wawasan Kebangsaan (TWK)</li>
                        </ul>
                        <h5>Tahap 3: Tes Lanjutan 1</h5>
                        <ul>
                            <li>Tes Kesehatan Tahap 1</li>
                        </ul>
                        <h5>Tahap 4: Tes Lanjutan 2</h5>
                        <ul>
                            <li>Tes Psikologi, Integritas dan Kejujuran</li>
                        </ul>
                        <h5>Tahap 5: Tes Lanjutan 3</h5>
                        <ul>
                            <li>Tes Kesehatan Tahap 2</li>
                            <li>Tes Samapta dan Pemeriksaan Penampilan</li>
                        </ul>

                        <h1>Fakultas dan Program Studi IPDN</h1>
                        <div className="row">
                            {/* Fakultas Column */}
                            <div className="col-lg-6">
                                <h5>Fakultas</h5>
                                <ul>
                                    <li>FPP FAKULTAS POLITIK PEMERINTAHAN</li>
                                    <li>FMP FAKULTAS MENEJEMEN PEMERINTAHAN</li>
                                    <li>FPM FAKULTAS PERLINDUNGAN MASYARAKAT</li>
                                </ul>
                            </div>
                            {/* Program Studi Column */}
                            <div className="col-lg-6">
                                <h5>Program Studi</h5>
                                <ul>
                                    <li>D4 – Teknologi Rekayasa Informasi Pemerintahan</li>
                                    <li>D4 – Administrasi Pemerintahan Daerah</li>
                                    <li>D4 – Manajemen Keamanan Dan Keselamatan Publik</li>
                                    <li>D4 – Praktik Perpolisian Tata Pamong</li>
                                    <li>D4 – Studi Kebijakan Publik</li>
                                    <li>D4 – Studi Kependudukan Dan Pencatatan Sipil</li>
                                    <li>D4 – Manajemen Sumber Daya Manusia Sektor Publik</li>
                                    <li>D4 – Keuangan Publik</li>
                                    <li>D4 – Pembangunan Ekonomi Dan Pemberdayaan Masyarakat</li>
                                    <li>D4 – Politik Indonesia Terapan</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    {/* Section with title above ProjectSekdinIPDN */}
                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Alumni DELTA lolos IPDN</h3>
                    <div style={{ marginTop: '30px' }}>
                        <ProjectSekdinIPDN />
                    </div>

                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Sekolah Kedinasan Lain :</h3>
                    <div style={{ marginTop: '30px' }}><PartnerSection /></div>
                         <div className="text-center mt-5"> 
                             <Link
                                 to="/" 
                                 className="btn btn-primary"
                                 onClick={ClickHandler}
                                 style={{ display: 'inline-block' }} // Ensure the button is inline-block for centering
                             >
                                 <span className="btn_label" data-text="Kembali">Kembali</span>
                                 <span className="btn_icon">
                                     <i className="fa-solid fa-arrow-up-right"></i>
                                 </span>
                             </Link>
                         </div>                   
                </section>
            </main>

            {/* Contact */}
            <WhatsappButton />
            <Footer />
        </Fragment>
    );
};

export default SekdinPage;
