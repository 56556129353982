import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import ProjectSekdinIPDN from '../../components/ProjectSection/ProjectSectionSTMKG';
import aImg from '../../images/artikelsekdin/stmkg1.jpg';
import aImg2 from '../../images/artikelsekdin/stmkg2.jpg';
import aImg3 from '../../images/artikelsekdin/stmkg3.jpg';
import aImg4 from '../../images/artikelsekdin/stmkg4.jpeg';
import WhatsappButton from '../../components/WhatsappButton/whatsappbutton';

const SekdinPage = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Sekolah Tinggi Meteorologi, Klimatologi, dan Geofisika (STMKG)'} />
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="STMKG - About" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="STMKG - About" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* New Section for STMKG Information */}
                <section className="about-info-section bg-white py-5">
                    <div className="container">
                        <h1 className="section-title mb-4">Tentang STMKG</h1>
                        <p style={{ textAlign: 'justify', marginBottom: '10px' }}>
                            Sekolah Tinggi Meteorologi, Klimatologi, dan Geofisika (STMKG) adalah perguruan tinggi kedinasan di bawah Badan Meteorologi, Klimatologi, dan Geofisika (BMKG) yang bertujuan untuk menghasilkan tenaga profesional di bidang meteorologi, klimatologi, geofisika, dan instrumentasi. STMKG menyediakan pendidikan vokasi dengan kurikulum yang dirancang untuk memenuhi kebutuhan operasional BMKG.
                        </p>
                        <p style={{ textAlign: 'justify', marginBottom: '40px' }}>
                            STMKG menawarkan berbagai program studi yang meliputi analisis meteorologi, pengamatan perubahan iklim, geofisika, dan pengembangan teknologi instrumentasi. Lulusan STMKG diharapkan dapat bekerja di BMKG dan instansi pemerintah terkait untuk menjaga ketahanan dan keselamatan alam Indonesia.
                        </p>
                        
                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg3} alt="STMKG Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Kampus STMKG - Mencetak Profesional di Bidang Meteorologi, Klimatologi, dan Geofisika</p>
                        </div>

                        <h1 className="section-title mt-0">Persyaratan Pendaftaran STMKG</h1>
                        <ol>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Umum</h5>
                                <li>Warga Negara Indonesia</li>
                                <li>Usia minimal 15 tahun dan maksimal 23 tahun pada 1 September 2023</li>
                                <li>Sehat jasmani dan rohani, serta bebas narkoba</li>
                                <li>Belum menikah dan bersedia tidak menikah selama mengikuti pendidikan di STMKG hingga pengangkatan PNS</li>
                                <li>Tidak buta warna (baik total maupun parsial)</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Administrasi</h5>
                                <li>Pas foto terbaru</li>
                                <li>Kartu Identitas (KTP/KIA)</li>
                                <li>Kartu Keluarga</li>
                                <li>Akta Kelahiran</li>
                                <li>Ijazah dan Transkrip Nilai atau Rapor Kelas 12 Semester Ganjil</li>
                                <li>Surat Keterangan Orang Asli Papua (SKOAP) bagi pendaftar jalur afirmasi</li>
                            <h5 style={{ marginTop: '10px' }}>Persyaratan Khusus</h5>
                                <li>Tidak sedang menjalani atau terancam hukuman pidana karena melakukan kejahatan</li>
                                <li>Tidak bertindik atau bekas ditindik telinganya atau anggota badan lainnya bagi peserta pria, kecuali karena ketentuan agama/adat</li>
                                <li>Tidak bertato</li>
                                <li>Tidak menggunakan kacamata/lensa kontak</li>
                                <li>Belum pernah menikah/kawin, bagi pendaftar wanita belum pernah hamil/melahirkan</li>
                        </ol>

                        {/* New Image and Subheading */}
                        <div className="text-center mb-4">
                            <img src={aImg4} alt="STMKG Campus" className="rounded-rect" style={{ maxWidth: '50%', height: 'auto', borderRadius: '10px' }} />
                            <p className="mt-3" style={{ marginTop: '20px' }}>Kampus STMKG - Mencetak Profesional di Bidang Meteorologi, Klimatologi, dan Geofisika</p>
                        </div>

                        <h1 className="marginBottom:'10px'">Fakultas dan Program Studi STMKG</h1>
                        <div className="row">
                            {/* Fakultas Column */}
                            <div className="col-lg-6">
                                <h5>Fakultas</h5>
                                <ul>
                                    <li>Fakultas Meteorologi</li>
                                </ul>
                            </div>
                            {/* Program Studi Column */}
                            <div className="col-lg-6">
                                <h5>Program Studi</h5>
                                <ul>
                                    <li>D4 – Meteorologi</li>
                                    <li>D4 – Klimatologi</li>
                                    <li>D4 – Geofisika</li>
                                    <li>D4 – Instrumentasi-MKG</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Alumni DELTA lolos POLSTAT STIS</h3>
                    <div style={{ marginTop: '30px' }}>
                        <ProjectSekdinIPDN />
                    </div>
                    <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Sekolah Kedinasan Lain :</h3>
                    <div style={{ marginTop: '30px' }}><PartnerSection /></div>
                         <div className="text-center mt-5"> 
                             <Link
                                 to="/" 
                                 className="btn btn-primary"
                                 onClick={ClickHandler}
                                 style={{ display: 'inline-block' }} // Ensure the button is inline-block for centering
                             >
                                 <span className="btn_label" data-text="Kembali">Kembali</span>
                                 <span className="btn_icon">
                                     <i className="fa-solid fa-arrow-up-right"></i>
                                 </span>
                             </Link>  
                    </div>    
            </main>
            <Footer />
            <WhatsappButton />
        </Fragment>
    );
};

export default SekdinPage;
